import { t } from "i18next";
import { showNotification } from "../components/Notification";
import apiController from "./index";

export const createValidator = async (count: number) => {
    try {
        const result = await apiController.post('/validators', {count});
        if (result.status === 201) {
            return result.data;
        } 
    } catch (e: any) {
        showNotification('error', t("failedToCreate"), t("balanceError"))
    }
}

export const getValidator = async (current: number, pageSize: number, sort: string) => {
    try {
        const result = await apiController.get(`/validators?page=${current}&size=${pageSize}&sort=${sort}`);
        if (result.status === 200) {
            return result.data;
        }
        throw new Error('Failed to create list validators');
    } catch (e) {
        throw e;
    }
}

export const getDetailValidator = async (id: number) => {
    try {
        const result = await apiController.get(`/validators/detail/${id}`);
        if (result.status === 200) {
            return result.data;
        }
    } catch (e: any) {
        showNotification('error', 'Failed to get validator', e.response.data.message)
    }
}

export const unStaking = async (id: number) => {
    try {
        const result = await apiController.post(`/validators/unstake`, {index: id});
        if (result.status === 201) {
            return result.data;
        }
    } catch (e: any) {
        console.log(e);
        
        showNotification('error', 'Failed to unstake', "Can not do this action now!")
    }
}

