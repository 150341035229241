import React from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import XContact from "./XContact";
import TeleContact from "./TeleContact";

interface IErrorModal {
  isOpen: boolean;
  isClose: () => void;
}

const ErrorPopup: React.FunctionComponent<IErrorModal> = (props) => {
  const { isOpen, isClose } = props;
  const { t } = useTranslation();

  return (
    <Modal
      className="contact-modal"
      open={isOpen}
      onCancel={isClose}
      centered
      footer={null}
    >
      <div className="contact-modal-body">
        <div className="contact-modal-header">
          <span className="contact-modal-title">{t("maintain_1")}</span>
          <span className="contact-modal-description">
            {t("maintain_2")}<br />
          </span>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfu22-f070eXSYmdXQOCbQlXfIWUpOUMOqrENNaNuAkpC2sjg/viewform"
          className="link"
          target="_blank"
          rel="noreferrer"
          >
          {t("maintain_3")}</a>
        </div>

      </div>
      <div className="contact-modal-footer">
        <hr className="divider" />
        <div className="contact-modal-link">
          <a
            href={process.env.STAKE_VAULT_TERM}
            className="link"
            target="_blank"
            rel="noreferrer"
          >
            {t("termsOfService")}
          </a>
          <a
            href={process.env.STAKE_VAULT_POLICY}
            className="link"
            target="_blank"
            rel="noreferrer"
          >
            {t("privacyPolicy")}
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorPopup;
