import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface UnstakingSuccessProps {
	index: number
  withdrawalCredentials: string;
  balance: string;
}

const UnstakingSuccess: FunctionComponent<UnstakingSuccessProps> = ({
	index,
  withdrawalCredentials,
  balance,
}) => {
  const { t } = useTranslation();
  return (
    <div className="unstaking-container">
      <div className="unstaking-header">
        <div className="header">{t("unstakeSuccessTitle")}</div>
        <div className="unstaking-success">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="108"
            height="108"
            viewBox="0 0 108 108"
            fill="none"
          >
            <ellipse
              opacity="0.1"
              cx="53.9999"
              cy="53.1999"
              rx="50.8"
              ry="50.8"
              fill="#E8FBF3"
            />
            <ellipse
              cx="53.9999"
              cy="53.1999"
              rx="36.8"
              ry="36.8"
              fill="#66BB6A"
            />
            <path
              d="M67.5999 45.6001L50.5999 62.8001L40.3999 52.4801"
              stroke="white"
              stroke-width="8"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
					<div className="success-title">
						{t("validatorExited")}
					</div>
					<div className="unstaking-text-success"> 
						<span>{t("success_1")}</span>
						<span>{t("success_2")}</span>
					</div>
        </div>
      </div>
      <div className="unstaking-info">
        <div className="unstaking-value">
          <div className="line">
            <div className="title-line">
              <div>{t("validator_index")}</div>
            </div>
            <div className="value-line-1">{index}</div>
          </div>
          <div
            style={{ background: "#404040", height: "1px", width: "100%" }}
          />
          <div className="line">
            <div className="title-line">
              <div>{t("withdrawnBalance")}</div>
            </div>
            <div className="value-line">{String(balance)}{" "}ETH</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnstakingSuccess;
