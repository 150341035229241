import React, { FunctionComponent } from "react";

interface AlertIconProps {}

const AlertIcon: FunctionComponent<AlertIconProps> = () => {
  return (
    <div className="alert-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
      >
        <path
          d="M11 5.949L17.9025 17.8748H4.0975L11 5.949ZM11 2.2915L0.916672 19.7082H21.0833L11 2.2915Z"
          fill="#FFA726"
        />
        <path
          d="M11.9167 15.1248H10.0833V16.9582H11.9167V15.1248Z"
          fill="#FFA726"
        />
        <path
          d="M11.9167 9.62484H10.0833V14.2082H11.9167V9.62484Z"
          fill="#FFA726"
        />
      </svg>
    </div>
  );
};

export default AlertIcon;
