import React, { JSX } from "react";
import BaseHeader from "./BaseHeader";
import BaseFooter from "./BaseFooter";
import { useLoading } from "../../context/LoadingContext";
import FullScreenLoader from "./components/Loading";

interface base {
  children: JSX.Element;
}

const BasePage: React.FC<base> = ({ children }): JSX.Element => {
  const { isLoading } = useLoading();

  return (
    <>
      {isLoading && <FullScreenLoader isLoading={isLoading} />}
      <div className="dashboard-container">
        <BaseHeader isShowStakeNow={true} />
        {children}
        <BaseFooter />
      </div>
    </>
  );
};

export default BasePage;
