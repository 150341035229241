import React from "react";
import { Spin } from "antd";

const FullScreenLoader: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="full-screen-loader">
      <Spin size="large" />
    </div>
  );
};

export default FullScreenLoader;
