import React, { FunctionComponent, useEffect, useState } from "react";
import BasePage from "../../components/BasePage";
import { useTranslation } from "react-i18next";
import { showNotification } from "../../components/Notification";
import { useParams } from "react-router-dom";
import { Checkbox } from "antd";
import i18n from "i18next";
import { getDetailValidator, unStaking } from "../../api/stake.api";
import { StatusEnum } from "../../constant/constant";
import AlertIcon from "../../components/Common/AlertIcon";
import UnstakingSuccess from "./components/UnStakingSuccess";
import { ethers } from "ethers";
import { useLoading } from "../../context/LoadingContext";

interface UnStakingProps {}

interface IValidator {
  balance: number;
  createdAt: string;
  depositDataRoot: string;
  effective_balance: any;
  id: number;
  index: any;
  pubkey: string;
  status: StatusEnum;
  updatedAt: string;
  withdraw_credential: string;
}

interface IValidatorExit {
  pubkey: string;
  validatorIndex: string;
  successful: boolean;
  reason: string;
}

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const UnStaking: FunctionComponent<UnStakingProps> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [validatorInfo, setValidatorInfo] = useState<IValidator>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const { isLoading, startLoading, stopLoading } = useLoading();

  const handleUnstaking = async () => {
    startLoading();
    const result = await unStaking(validatorInfo?.index);
    if (!result) {
      stopLoading();
      return;
    }
    if (result[0].successful) {
      showNotification(
        "success",
        "Unstaking successfully",
        `Validator index: ${result[0].validatorIndex}`
      );
      setIsSuccess(true);
    } else {
      showNotification(
        "error",
        "Unstaking failed",
        `${result[0].reason} (validator index: ${result[0].validatorIndex})`
      );
    }
    stopLoading();
  };

  const fetchData = async () => {
    const result = await getDetailValidator(Number(id));
    if (result) {
      setValidatorInfo(result);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <BasePage>
      {isSuccess ? (
        <UnstakingSuccess
          index={validatorInfo?.index}
          withdrawalCredentials={validatorInfo?.withdraw_credential || ""}
          balance={ethers.formatUnits(
            validatorInfo?.balance.toString() || "0",
            "gwei"
          )}
        />
      ) : (
        <div className="unstaking-container">
          <div className="unstaking-header">
            <div className="header">{t("exitAndWithDrawal")}</div>
            <div className="unstaking-validator">
              <div className="unstaking-alert">
                <AlertIcon />
                <ul className="text-alert">
                  <li className="text-alert-item">{t("warning_1")}</li>
                  <li className="text-alert-item">{t("warning_2")}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="unstaking-info">
            <div className="unstaking-value">
              <div className="line">
                <div className="title-line">
                  <div>{t("validator_index")}</div>
                </div>
                <div className="value-line-1">{validatorInfo?.index}</div>
              </div>
              <div
                style={{ background: "#404040", height: "1px", width: "100%" }}
              />
              <div className="line">
                <div className="title-line">
                  <div>{t("status")}</div>
                </div>
                <div className="value-line">
                  {validatorInfo ? StatusEnum[validatorInfo.status] : ""}
                </div>
              </div>
              <div className="line">
                <div className="title-line">
                  <div>{t("unstakeBalance")}</div>
                </div>
                <div className="value-line">
                  {ethers.formatUnits(
                    validatorInfo?.balance.toString() || "0",
                    "gwei"
                  )}{" "}
                  ETH
                </div>
              </div>
              <div className="line">
                <div className="title-line">
                  <div>{t("protocolRewards")}</div>
                </div>
                <div className="value-line">
                  {ethers.formatUnits(
                    (
                      (validatorInfo?.balance || 0 -
                      validatorInfo?.effective_balance) || 0
                    ).toString() || "0",
                      "gwei"
                    )}{" "}
                  ETH
                </div>
              </div>
            </div>
            <div className="unstaking-confirm">
              <Checkbox
                onClick={() => setIsChecked(!isChecked)}
                value={isChecked}
                {...label}
              />
              {i18n.language === "en" ? (
                <div className="text-confirm">
                  I confirm I request to voluntarily exit validator with public
                  key{" "}
                  <span className="bold-text-confirm-1">
                    {" "}
                    {validatorInfo ? validatorInfo.pubkey : ""}
                  </span>{" "}
                  and my permission is valid until{" "}
                  <span className="bold-text-confirm-2">
                    {new Date().toString()}
                  </span>
                </div>
              ) : (
                <div className="text-confirm">
                  私はバリデータの退出を希望し、公開鍵{" "}
                  <span className="bold-text-confirm-1">
                    {" "}
                    {validatorInfo ? validatorInfo.pubkey : ""}
                  </span>{" "}
                  により確認します。私の許可は{" "}
                  <span className="bold-text-confirm-2">
                    {new Date().toString()}
                  </span>
                  まで有効です。
                </div>
              )}
            </div>
            <button
              onClick={handleUnstaking}
              disabled={!isChecked || isLoading || validatorInfo?.status === 4}
              className={`unstaking-button ${!isChecked || isLoading || validatorInfo?.status === 4? "disable" : ""}`}
            >
              <span>{t("requestExit")}</span>
            </button>
          </div>
        </div>
      )}
    </BasePage>
  );
};

export default UnStaking;
