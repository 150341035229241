import React, {useEffect, useState} from 'react';
import {Button, Modal} from "antd";
import { useSignMessage } from 'wagmi'
import {login, LoginResponse} from "../../api/authen.api";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import {showNotification} from "../Notification";
import { useTranslation } from 'react-i18next';

interface IModalSignMessage {
    isOpen: boolean;
    isClose: ()=> void;
    onSuccess : ()=> void;
}

const  ModalSignMessage: React.FunctionComponent<IModalSignMessage> = (props) => {
    const message = 'Welcome to StakeVault!'
    const {isOpen, isClose, onSuccess} = props
    const { data, isError, isSuccess, signMessage, error } = useSignMessage()
    const { t } = useTranslation();

    useEffect(() => {
        if (data) {
            login(message, data)
                .then((result: LoginResponse) => {
                    localStorage.setItem('accessToken', result.accessToken);
                    localStorage.setItem('refreshToken', result.refreshToken);
                    isClose();
                    onSuccess();
                })
                .catch((e) => {
                    console.log(e)
                    showNotification('error', 'Login failed', e.message)
                })

        }
    }, [data])



    return (
        <div>
            <Modal
                className="modal--body"
                title={t("signMessage")}
                open={isOpen}
                onCancel={isClose}
                centered
                footer={null}
            >
                <div className="modal--body__metamask-connect">
                    <div className="modal--body__content">
                        <h2>{t("pleaseSign")}</h2>
                        <h3>{t("welcomeToStakingVault")}</h3>
                        <div style={{ height: '26px' }} />
                    </div>


                    <div className="modal--body__actions" style={{textAlign: "center"}}>
                        <Button onClick={() => signMessage({message: message})}>
                        {t("signMessage").toUpperCase()}
                        </Button>
                    </div>
                        {isError ? <div>{error?.message}</div> : null}
                    </div>
            </Modal>
        </div>
)
}

export default ModalSignMessage;