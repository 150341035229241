import React, { useEffect, useState } from "react";
import { Pagination, Table } from "antd";
import type { TableColumnsType, TableProps } from "antd";

import { useAccount } from "wagmi";
import Copy from "../../../components/BasePage/components/Copy";
import EyeView from "../../../components/BasePage/components/EyeView";
import { useTranslation } from "react-i18next";
import { formatDateTime } from "../../../utils/formatData";
import { Link } from "react-router-dom";
import { getValidator } from "../../../api/stake.api";
import StatusChip from "../../../components/Common/StatusChip";
import { ethers } from "ethers";

type TableRowSelection<T> = TableProps<T>["rowSelection"];

export interface DataType {
  index: number;
  publicKey: string;
  balance: string;
  activationDate: string;
  status: number;
  actions: string;
}

const DashboardList = () => {
  const element = document.querySelector(".ant-empty-description");
  const [listValidator, setListValidator] = useState<DataType[]>([]);
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const fetchValidators = async (
    current: number = 1,
    pageSize: number = 10,
    sort: string = "asc"
  ) => {
    try {
      const response: any = await getValidator(current, pageSize, sort);

      if (response) {
        const formattedData = response.data.map((item: any, index: number) => ({
          index: item.index,
          publicKey: item.pubkey,
          balance: item.balance,
          activationDate: item.status === (3 || 4) ? 
            formatDateTime(new Date((Number(item.activationepoch) * 384 + Number(process.env.TIME_BLOCK_0))*1000).toISOString()) : "",
          status: item.status,
          actions: `${item.id}-${item.status}`,
        }));
        setPagination({
          ...pagination,
          current: response.page,
          total: response.total,
        });
        localStorage.setItem("totalValidator", response.total);
        
        setListValidator(formattedData);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handlePageChange = (page: number) => {
    fetchValidators(page);
  };

  if (element && listValidator.length === 0) {
    element.textContent = t("noData");
  }

  const columns: TableColumnsType<DataType> = [
    {
      title: () => {
        return t("index");
      },
      dataIndex: "index",
      sorter: (a, b) => a.index - b.index,
      width: 100,
      render: (index: number) => {
        return index || "--";
      },
    },
    {
      title: () => {
        return t("validationPublicKey");
      },
      dataIndex: "publicKey",
      sorter: (a, b) => a.publicKey.localeCompare(b.publicKey),
      width: 200,
      render: (text: string) => (
        <div className="table-cell-content">
          <span className="elipise-text">
            <div className="text">{text}</div>
            <div className="actions">
              <Copy contentCopy={text} color="#BBBBBB" />
            </div>
          </span>
        </div>
      ),
    },
    {
      title: () => {
        return t("balance");
      },
      dataIndex: "balance",
      sorter: (a, b) => a.balance.localeCompare(b.balance),
      width: 160,
      align: "right",
      render: (text: number) => `${ethers.formatUnits(text?.toString() || "0", "gwei")} ETH`
    },
    {
      title: () => {
        return t("activationDate");
      },
      dataIndex: "activationDate",
      sorter: (a, b) => a.activationDate.localeCompare(b.activationDate),
      width: 200,
      align: "right",
    },
    {
      title: () => {
        return t("status");
      },
      dataIndex: "status",
      sorter: (a, b) => a.index - b.index,
      width: 100,
      align: "right",
      render: (text: string) => (
        <div className="table-cell-content content-end">
          <StatusChip text={text} />
        </div>
      ),
    },
    {
      title: () => {
        return t("action");
      },
      dataIndex: "actions",
      align: "right",
      sorter: (a, b) => a.actions.localeCompare(b.actions),
      width: 120,
      render: (status: string) =>
        Number(status.split("-")[1]) === (3 || 4) ? (
          <Link
            to={`/unstaking/${status.split("-")[0]}`}
            style={{ fontSize: "20px", cursor: "pointer" }}
          >
            <EyeView />
          </Link>
        ) : (
          "--"
        ),
    },
  ];

  useEffect(() => {
    fetchValidators();
  }, []);

  return (
    <div className="dashboard-validators">
      <div className="header-validators">
        <button className="button-validators">
          <div>{t("validatorList")}</div>
        </button>
        <div className="total-text">
          <div className="text-1">{t("totalRecord")}:</div>
          <div className="text-2">{pagination.total}</div>
        </div>
      </div>
      <Table
        columns={columns}
        pagination={false}
        dataSource={listValidator}
        scroll={{ x: "max-content" }}
      />
      <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={handlePageChange}
        showSizeChanger={false}
        style={{ marginTop: "20px", textAlign: "center" }}
      />
    </div>
  );
};

export default DashboardList;
