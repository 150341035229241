import React, {useEffect, useState} from "react";
import {useAccount, useBalance, useWriteContract} from "wagmi";
import { Modal } from "antd";
import { config } from "../../../config/wagmi";
import { getChainId } from "@wagmi/core";
import CheckCircle from "../../../assets/image/check_circle.png";
import { useTranslation } from "react-i18next";
import StakingABI from '../../../abis/StakeBlitzRepository.json'
import { formatEther } from "viem";
import {ethers, isError} from 'ethers'
import { showNotification } from "../../../components/Notification";
import { useLoading } from "../../../context/LoadingContext";

export interface IStakingData {
  pubkeys: string;
  withdrawalCredentials: string;
  signatures: string;
  depositDataRoots: string[];
}

interface IModalSignMessage {
  isOpen: boolean;
  isClose: () => void;
  stakingValue: number;
  changeStakingValue: (value: number) => void;
  value: number;
  stakingData: IStakingData;
}

const ModalStaking: React.FunctionComponent<IModalSignMessage> = (props) => {
  const { isOpen, isClose, stakingValue, changeStakingValue, value, stakingData } = props;
  const { pubkeys, withdrawalCredentials, signatures, depositDataRoots } = stakingData;
  const [disableStaking, setDisableStaking] = useState<boolean>(false);
  const {writeContract, isError, isSuccess, isPending, error} = useWriteContract()
  const { address, isConnected } = useAccount();
  const { data } = useBalance({
    address: address,
    chainId: getChainId(config),
  });
  const { t } = useTranslation();
  const { isLoading, startLoading, stopLoading } = useLoading();

  const handleStaking = async () => {
    setDisableStaking(true);
    startLoading();
    writeContract({
      abi: StakingABI,
      address: process.env.STAKE_BLITZ_CONTRACT as any,
      functionName: 'staking',
      args: [
        pubkeys,
        withdrawalCredentials,
        signatures,
        depositDataRoots,
      ],
      value: ethers.parseEther(value.toString())
    })
  }

  useEffect(() => {
    if (error) {
      showNotification('error', t("stakingFailed"), t("rejectRequest"))
    }
    stopLoading();
    isClose();
  }, [error]);

  return (
      <Modal
        className="modal--body"
        open={isOpen}
        onCancel={isClose}
        title={t("signMessage")}
        centered
        width={582}
        closeIcon={true}
        footer={null}
      >
        <div className="staking-confirm-modal">
          <div className="staking-option">
            <div className="network-list">
              <div className="network">
                <div className="left-side">
                  <div>{t("classicStaking")}</div>
                  <p>{t("stakeNatively")}</p>
                </div>
                <div className="right-side">
                  <img src={CheckCircle} alt="" />
                </div>
                <span className="blur" style={{ backgroundColor: "#7BFFC8" }} />
              </div>
            </div>
          </div>
          <div className="stake-validator">
            <div className="input-line">
              <div className="left-side">
                <input
                  type="number"
                  disabled={true}
                  className="staking-value-modal"
                  onChange={(e) => changeStakingValue(Number(e.target.value))}
                  value={stakingValue}
                />
              </div>
              <button onClick={handleStaking} disabled={isLoading} className="right-side">
                <div className="stake-button">{t("stake")}</div>
              </button>
            </div>
          </div>
        </div>
        <div className="footer-stake-modal">
          <div className="left-side">{t("availableBalance")}</div>
          <div className="right-side">
            {data ? parseFloat(formatEther(data?.value)).toFixed(5) : "0.00000 ETH"} {data?.symbol}
            <span className="max-value">Max</span>
          </div>
        </div>
      </Modal>
  );
};

export default ModalStaking;
