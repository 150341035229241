import React from "react";
import './index.css';
import highYieldImg from '../../assets/image/high_yield.png'
import flexibleleLockImg from '../../assets/image/flexible_lock_periods.png'
import safetyReliabilityImg from '../../assets/image/safety_reliability.png'
import supportImg from '../../assets/image/support.png'
import StakingTable from "./StakingTable";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';

const info = [
  {
    image: highYieldImg,
    title: 'reason_title_1',
    description: 'reason_des_1',
    backgroundColor: '#2D4FC9'
  },
  {
    image: flexibleleLockImg,
    title: 'reason_title_2',
    description: 'reason_des_2',
    backgroundColor: '#B74322'
  },
  {
    image: safetyReliabilityImg,
    title: 'reason_title_3',
    description: 'reason_des_3',
    backgroundColor: '#CB9817'
  },
  {
    image: supportImg,
    title: 'reason_title_4',
    description: 'reason_des_4',
    backgroundColor: '#08B27E'
  },
]


function Staking() {
  const { t } = useTranslation(); 
  
  return <div className="intro-staking">
      <div className="staking-about">
          <div className="staking-title">
            {
              i18n.language === "en" ? (
                <>
                  <p>{t("whatIs")}</p>
                  <p>Staking Blitz?</p>
                </>) : (
                <>
                  <p>SVN Staking</p>
                  <p>Blitz {t("whatIs")}?</p>
                </>
              )
            }
              
          </div>
          <div className="staking-description">
              <p className="description-first">{t("des_staking_1")}</p>
              <p className="description-second">{t("des_staking_2")}</p>
          </div>
      </div>
      <div className="info-staking">
          {
            info.map((item, index) => {
              return <div className="rectangle" style={{backgroundColor: item.backgroundColor}}>
                  <span className="blur" style={{backgroundColor: "rgba(255, 255, 255, 0.9)"}} />
                  <div className="image-staking">
                      <img src={item.image} alt="" />
                  </div>
                  <div className="text-staking">
                      <p className="title-staking">{t(item.title)}</p>
                      <p className="description-staking">{t(item.description)}</p>
                  </div>
              </div>
            })
          }
        </div>

        <div className="staking-table">
          <StakingTable />
      </div>
  </div>;
}

export default Staking;
