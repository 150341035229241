import React, { FunctionComponent } from "react";
import { ETHERSCAN_URL } from "../../../utils/constant";

interface ExportProps {
  address?: string;
}

const Export: FunctionComponent<ExportProps> = ({ address }) => {
  return (
    <a
      className="icon open-address"
      target="_blank"
      href={`${ETHERSCAN_URL}address/${address}`}
      rel="noreferrer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M16.6667 6.66671H12.5V8.33337H15V17.5H4.99999V8.33337H7.49999V6.66671H3.33333V19.1667H16.6667V6.66671Z"
          fill="#BBBBBB"
        />
        <path
          d="M9.16666 13.3334H10.8333V4.16671H13.3333L9.99999 0.833374L6.66666 4.16671H9.16666V13.3334Z"
          fill="#BBBBBB"
        />
      </svg>
    </a>
  );
};

export default Export;
