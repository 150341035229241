import React, { FunctionComponent } from "react";

interface EthereumIconProps {
    
}
 
const EthereumIcon: FunctionComponent<EthereumIconProps> = () => {
    return (  
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M4.57153 10.1738L10.1079 0.625L15.6454 10.1738L10.1079 13.576L4.57153 10.1738Z" fill="#1F1F1F"/>
        <path d="M4.57153 11.2656L10.1079 19.375L15.6485 11.2656L10.1079 14.6656L4.57153 11.2656Z" fill="#1F1F1F"/>
    </svg>);
}
 
export default EthereumIcon;