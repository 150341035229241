import apiController from "./index";

export interface LoginResponse {
    accessToken: string;
    refreshToken: string;
    user: any;
}
export const login = async (messsage: string, signature: string): Promise<LoginResponse> => {
    try {
        const result = await apiController.post('/auth/verify', {
            message: messsage,
            signature: signature
        });
        if (result.status !== 200) {
            throw Error("Login failed");
        }
        return {
            accessToken: result.data.accessToken,
            refreshToken: result.data.refreshToken,
            user: result.data.user
        }
    }catch (e) {
        throw e;
    }
}

export const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
}