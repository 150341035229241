import React, { useEffect, useState } from "react";
import { useAccount, useSwitchChain } from "wagmi";
import { config } from "../../config/wagmi";
import { getChainId } from '@wagmi/core';

import ModalDisconnect from "../Wallet/ModalDisconnect";
import { Link, useNavigate } from "react-router-dom";
import "../../index.css";
import EthereumIcon from "./components/Ethereum";
import DropdownIcon from "./components/Dropdown";
import Copy from "./components/Copy";
import { useTranslation } from "react-i18next";
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { truncateAddress } from "../../utils/formatData";
import ErrorPopup from "./components/ErrorPopup";

interface BaseHeaderProps {
  isShowStakeNow: boolean;
}

const BaseHeader: React.FC<BaseHeaderProps> = ({ isShowStakeNow }) => {
  const { address, isConnected } = useAccount();
  const { chains, switchChain } = useSwitchChain();
  const [chainId, setChainId] = useState<number>(getChainId(config));
  const [selectedValue, setSelectedValue] = useState<string | number>(getChainId(config));
  const [currentNetworkName, setCurrentNetworkName] = useState<any>(chains?.find((chain: any) => chain?.id === getChainId(config))?.name);
  const [isOpenDisconnect, setOpenDisconnect] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isOpenErrorPopup, setErrorPopup] = useState<boolean>(false);

  const navigate = useNavigate();
  const [language, setLanguage] = useState<string>(localStorage.getItem("language") || "EN");
  const { i18n, t } = useTranslation();

  const changeLanguage = (lng: string) => {
    setLanguage(lng.toUpperCase());
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    switchChain(getChainId(config) as any);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 950 && isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMenuOpen]);

  const handleChange = async (currentChain: any) => {
    const newChainId = parseInt(currentChain?.id);
    switchChain({ chainId: currentChain.id as any });
    setChainId(newChainId);
    setSelectedValue(currentChain.id);
    setCurrentNetworkName(currentChain.name);
  };

  const modal = useWeb3Modal();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLanguageChange = (lng: string) => {
    localStorage.setItem("language", lng.toUpperCase());
    changeLanguage(lng);
    setIsDropdownOpen(false);
  };

  return (
    <div>
      <div className="header-container">
        <div className="left-header">
          <div className="logo-header">
            <h3><Link to="/" className="router-link-logo">STAKING VAULT</Link></h3>
          </div>
          {/*<div>*/}
          {/*  <Link className="dashboard-link" to={"/dashboard"}>{t("dashboard")}</Link>*/}
          {/*</div>*/}
        </div>
        <div className="right-header">
          <button className={`menu-button ${isMenuOpen ? "open" : ""}`} onClick={toggleMenu}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </button>
          <div className="button-group">
            <div className="switch-button" onClick={toggleDropdown}>
              <div>{language === "EN" ? "ENGLISH" : "日本語"}</div>
              <DropdownIcon color={"#a1b2bf"}/>
              {isDropdownOpen && (
                <div className="dropdown-menu">
                  <div className="dropdown-item" onClick={() => handleLanguageChange("en")}>ENGLISH</div>
                  <div className="dropdown-item" onClick={() => handleLanguageChange("ja")}>日本語</div>
                </div>
              )}
            </div>
            {isConnected && (
              <button className="stake-button">
              <Link to={"/stake"} className="router-link">{t("stakeNow")}</Link>
            </button>
            )}
            {isConnected && (
              <button className="stake-button">
                <EthereumIcon/> Ethereum
              </button>
            )}
            <div className="connect-wallet open-connect-wallet-btn">
              {isConnected ? (
                <div className="connect-wallet-button">
                  <span className="address-text" onClick={() => setOpenDisconnect(true)}>
                    {truncateAddress(String(address))}
                  </span>
                  <Copy contentCopy={String(address)} color="white"/>
                </div>
              ) : (
                <button onClick={() => setErrorPopup(true)} className="connect-wallet-button">
                  {t("connectWallet")}
                </button>
              )}
            </div>
          </div>
          <div className={`menu-overlay ${isMenuOpen ? "open" : ""}`}>
            <button className="switch-button-mobile" onClick={() => handleLanguageChange(language === "EN" ? "ja" : "en")}>
              {language === "EN" ? "ENGLISH" : "日本語"}
            </button>
            {isConnected && (
              <button className="overlay-button">
                <Link to={"/stake"} className="router-link">{t("stakeNow")}</Link>
              </button>
            )}
            {isConnected && (
              <button className="overlay-button">
                <EthereumIcon/> Ethereum
              </button>
            )}
            <div>
              {isConnected ? (
                <div className="connect-wallet-button">
                  <span className="address-text" onClick={() => setOpenDisconnect(true)}>
                    {truncateAddress(String(address))}
                  </span>
                  <Copy contentCopy={String(address)} color="white"/>
                </div>
              ) : (
                <button onClick={() => setErrorPopup(true)} className="connect-wallet-button">
                  {t("connectWallet")}
                </button>
              )}
            </div>
            <ErrorPopup isClose={() => setErrorPopup(false)} isOpen={isOpenErrorPopup}/>
          </div>
        </div>
      </div>
      <ModalDisconnect isOpen={isOpenDisconnect} isClose={() => setOpenDisconnect(false)} />
    </div>
  );
};

export default BaseHeader;
