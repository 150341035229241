import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import EthereumStaking from '../pages/Stake';
import VerifySuccess from '../pages/VerifyNotification';
import Introduce from '../pages/Introduce';
import UnStaking from '../pages/Unstaking';



const router = (
    <Router>
        <Routes>
            <Route path="/" element={<Introduce />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/stake" element={<EthereumStaking />} />
            <Route path="/unstaking/:id" element={<UnStaking />} />
            <Route path="/user/verify/success" element={<VerifySuccess />} />
        </Routes>

    </Router>
);

export default router;