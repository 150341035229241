import React, {useEffect, useState} from 'react';
import {Button, Modal} from "antd";
import {useAccount, useBalance, useDisconnect} from 'wagmi'

import {config} from "../../config/wagmi";
import {logout} from "../../api/authen.api";
import { ETHERSCAN_URL } from '../../utils/constant';
import {getChainId} from "@wagmi/core";
import "../../assets/styles/modal-disconnect.css";
import Export from '../BasePage/components/Export';
import Copy from '../BasePage/components/Copy';
import { useTranslation } from 'react-i18next';


interface IModalSignMessage {
    isOpen?: boolean;
    isClose: ()=> void;
}


const ModalDisconnect: React.FunctionComponent<IModalSignMessage> = (props) => {

    const { connectors, disconnect } = useDisconnect()

    const {isOpen, isClose} = props
    const { address, isConnected } = useAccount()
    const chainId = getChainId(config)
    const { data  } = useBalance({
        address: address,
        chainId: chainId
    })
    const { t } = useTranslation();

    const disconnectAccount = async () => {
        disconnect()
        logout()
        isClose()
    }

    return (
        <div>
            <Modal
                title={t("account")}
                open={isOpen}
                onCancel={isClose}
                onOk={isClose}
                width={464}
                centered
                footer={null}
            >
                <div className='modal-disconnect'>
                    <div className='account-connect'>
                        <div className='account-connect__connect-status'>
                            {t("connectedWithWallet")}
                        </div>
                        <div className='account-connect__disconnect-btn'>
                            <Button type="text" onClick={disconnectAccount}>{t("disconnect")}</Button>
                        </div>
                    </div>
                    <div className='account-address'>
                        <div className='account-address__img'>
                        </div>
                        <div className="account-address__address-block">
                            <div className="account-address__address-block__wallet-address">
                                <span>{address}</span>
                            </div>
                            <div className="account-address__address-block__handle-address">
                                <div className="icon copy-address">
                                    <Copy contentCopy={String(address)} color={'#BBBBBB'} />
                                </div>
                                <Export address={address}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ModalDisconnect;
