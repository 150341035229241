import * as React from "react";

import BaseHeader from "../../components/BasePage/BaseHeader";
import BaseFooter from "../../components/BasePage/BaseFooter";
import { Typography } from "antd";
const { Title, Paragraph } = Typography;

const VerifySuccess = () => {
  return (
    <div>
      <main>
        <BaseHeader isShowStakeNow={true} />{" "}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            backgroundColor: "#282c34",
          }}
        >
          <Title level={2} style={{ color: "white" }}>
            Email Verification Successful
          </Title>
          <Paragraph style={{ color: "white" }}>
            Your email has been successfully verified. You can now access your
            dashboard.
          </Paragraph>
          <button
            type="submit"
            className="btn-network-stake-now c-eGFxFQ c-eGFxFQ-joQqKz-cv c-eGFxFQ-jhrxKZ-padding-sm c-eGFxFQ-gJEquz-cv c-eGFxFQ-iQyGwM-cv c-eGFxFQ-ecuPMK-cv c-eGFxFQ-ibnCHNK-css"
          >
            <span className="c-bHwuwj c-bHwuwj-qmjtS-variant-secondaryCod c-bHwuwj-LPMyz-uppercase-true c-bHwuwj-idDfRUr-css">
              <a href="/dashboard">Go to Dashboard</a>
            </span>
          </button>
        </div>
        <BaseFooter />
      </main>
    </div>
  );
};

export default VerifySuccess;
