import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";

import BasePage from "../../components/BasePage";
import CheckCircle from "../../assets/image/check_circle.png";
import ModalStaking, { IStakingData } from "./components/ModalStaking";
import { useTranslation } from "react-i18next";
import { createValidator } from "../../api/stake.api";
import { showNotification } from "../../components/Notification";
import AlertIcon from "../../components/Common/AlertIcon";
import { RewardData } from "../Dashboard";
import { getAnnualRewardRate } from "../../api/user.api";
import { useLoading } from "../../context/LoadingContext";

const EthereumStaking = () => {
  const { address, isConnected } = useAccount();
  const { t } = useTranslation();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const [stakingValue, setStakingValue] = useState<number>(0);
  const [listValidators, setListValidators] = useState<IStakingData>({
    pubkeys: "",
    withdrawalCredentials: "",
    signatures: "",
    depositDataRoots: [],
  });
  const [currentWithdrawalAddress, setCurrentWithdrawalAddress] =
    useState<string>();
  const [percentage, setPercentage] = useState<RewardData>();
  const [isOpenStakingModal, setIsOpenStakingModal] = useState<boolean>(false);

  const handleStaking = async () => {
    if (!Number.isInteger(stakingValue) || stakingValue <= 0) {
      showNotification("error", t("stakingFailed"), t("positiveNumberError"));
      return;
    }
    startLoading();
    const stakingData = await createValidator(stakingValue);
    if (!stakingData) {
      // showNotification("error", t("stakingFailed"), "Something wrong");
      stopLoading();
      return;
    }

    setListValidators(stakingData.stakefish_response);
    stopLoading();
    setIsOpenStakingModal(true);
  };

  const calculateRate = (rated: number) => {
    const totalValidator = Number(localStorage.getItem("totalValidator"));
    if (!rated || isNaN(rated) || !totalValidator || isNaN(totalValidator)) {
      return 0.0;
    }

    const result = (rated / 52) * totalValidator;
    return parseFloat(result.toFixed(5));
  };

  const handleChangeStakingValue = (value: number) => {
    setStakingValue(Number(value));
  };

  const fetchData = async () => {
    const stakeFishData = await getAnnualRewardRate();
    setPercentage(stakeFishData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setCurrentWithdrawalAddress(address);
  }, [address]);

  return (
    <BasePage>
      <div className="staking-container">
        <div className="staking-header">
          <div className="header">{t("ethereumStaking")}</div>
          <div className="description">{t("unrivaledEthereum")}</div>
        </div>
        <div className="staking-option">
          <div className="network-list">
            <div className="network">
              <div className="left-side">
                <div>{t("classicStaking")}</div>
                <p>{t("stakeNatively")}</p>
              </div>
              <div className="right-side">
                <img src={CheckCircle} alt="" />
              </div>
              <span className="blur" style={{ backgroundColor: "#7BFFC8" }} />
            </div>
          </div>
        </div>
        <div className="stake-validator">
          <div className="validator-header">{t("validators")}</div>
          <div className="input-line">
            <div className="left-side">
              <input
                type="number"
                className="staking-value"
                onChange={(e) => setStakingValue(Number(e.target.value))}
                value={stakingValue}
              />
              <div className="adorn-end-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  onClick={() => {
                    if (stakingValue > 0) {
                      setStakingValue((prev) => prev - 1);
                    }
                  }}
                >
                  <path
                    d="M19 13H5V11H19V13Z"
                    fill="white"
                    fill-opacity="0.56"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  onClick={() => setStakingValue((prev) => prev + 1)}
                >
                  <path
                    d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                    fill="white"
                    fill-opacity="0.56"
                  />
                </svg>
              </div>
            </div>
            <button disabled={isLoading} onClick={() => handleStaking()} className={`right-side ${isLoading ? "disable" : ""}`}>
              <div className="stake-button">{t("stake")}</div>
            </button>
          </div>
          <div className="stake-info-card">
            <div className="line">
              <div className="title-line">
                <div>{t("amount")}</div>
              </div>
              <div className="value-line">{stakingValue * 32} ETH</div>
            </div>
            <div className="line">
              <div className="title-line">
                <div>{t("protocolRewardsPerWeek")}</div>
              </div>
              <div className="value-line">
                {localStorage.getItem("totalValidator") && percentage
                  ? `${(
                      (calculateRate(percentage.protocolRewardRate) *
                        stakingValue *
                        32) /
                      100
                    ).toFixed(5)} ETH (${calculateRate(
                      percentage.protocolRewardRate
                    )}% APR)`
                  : "0.00000 ETH (0.00% APR)"}
              </div>
            </div>
            <div className="line">
              <div className="title-line">
                <div>{t("tipMevPoolEarningPerWeek")}</div>
              </div>
              <div className="value-line">
                {localStorage.getItem("totalValidator") && percentage
                  ? `${(
                      (calculateRate(percentage.tipNewRewardRate) *
                        stakingValue *
                        32) /
                      100
                    ).toFixed(5)} ETH (${calculateRate(
                      percentage.tipNewRewardRate
                    )}% APR)`
                  : "0.00000 ETH (0.00% APR)"}
              </div>
            </div>
          </div>

          <div className="stake-alert">
            <AlertIcon />
            <div className="text">{t("theStartOfStakingWillDependOn")}</div>
          </div>
        </div>
        <ModalStaking
          isClose={() => setIsOpenStakingModal(false)}
          isOpen={isOpenStakingModal}
          stakingValue={stakingValue}
          changeStakingValue={handleChangeStakingValue}
          stakingData={listValidators}
          value={stakingValue * 32}
        />
      </div>
    </BasePage>
  );
};

export default EthereumStaking;
