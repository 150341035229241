
import * as React from "react";

import BaseHeader from '../../components/BasePage/BaseHeader';
import BaseFooter from "../../components/BasePage/BaseFooter";
import IntroHeader from "./IntroHeader";
import Staking from "./Staking";
import Participate from "./Participate";
import Reason from "./Reason";



const Introduce = () => {
  return (
    <div className="dashboard-container introduce">
      <BaseHeader isShowStakeNow={true} />
        <IntroHeader />
        <Staking />
        <Participate />
        <Reason />
      <BaseFooter />
    </div>
  )
}

export default Introduce;
