import {useAccount, useBalance, useReadContract, useWriteContract} from "wagmi";
import { config } from "../../config/wagmi";
import * as React from "react";
import { useEffect, useState } from "react";
import ModalSignMessage from "../../components/Wallet/ModalSignMessage";
import { getChainId } from "@wagmi/core";

import { getAnnualRewardRate, getUserProfile, UserProfileResponse } from "../../api/user.api";
import DashboardList from "./components/DashboardList";
import CurrencyExchange from "../../assets/image/currency_exchange.png";
import TimeLine from "../../assets/image/timeline.png";
import BasePage from "../../components/BasePage";
import { useTranslation } from "react-i18next";
import StakingABI from '../../abis/StakeBlitzRepository.json'
import { formatEther } from "viem";
import { ethers } from "ethers";

export interface RewardData {
  id: number;
  protocolRewardRate: number;
  tipNewRewardRate: number;
  syncedAt: string;
  createdAt: string;
  updatedAt: string;
}

interface UserClaimInfo {
  claimedReward: bigint | number | string;
  totalReward: bigint | number | string;
  unclaimedReward: bigint | number | string;
}


const Dashboard = () => {
  const { address, isConnected } = useAccount();
  const [openSignMessage, setOpenSignMessage] = useState<boolean>(false);
  const [percentage, setPercentage] = useState<RewardData>();
  const chainId = getChainId(config);
  const [userClaimedInfo, setUserClaimedInfo] = useState<UserClaimInfo | null>(null);
  const {writeContract} = useWriteContract()
  
  const { data } = useBalance({
    address: address,
    chainId
  });
  // const [userData, setUserData] = useState<UserProfileResponse | null>(null);
  const { t } = useTranslation();

  const {data: userReward, isError, isSuccess} = useReadContract({
    address: process.env.STAKE_BLITZ_CONTRACT as any,
    abi: StakingABI,
    functionName: 'getUserInformation',
    chainId,
    args: [address],
  });

  useEffect(() => {
    if (isSuccess) {
      const result = userReward as UserClaimInfo;
      const claimedMount = Number(ethers.formatEther(result.claimedReward))
      const unclaimAmount = Number(ethers.formatEther(result.unclaimedReward))
      const totalAmount = Number(ethers.formatEther(result.totalReward))
      
      setUserClaimedInfo({
        claimedReward: claimedMount.toFixed(5),
        unclaimedReward: unclaimAmount.toFixed(5),
        totalReward: totalAmount.toFixed(5)
      })
    }

  }, [isSuccess]);

  const fetchDataOnScreen = async () => {
    const stakeFishData = await getAnnualRewardRate();
    setPercentage(stakeFishData);
    
    // const useReward = useReadContract({
    //   address: '0x921A064D40AbFC0810eAcd0aaE4b8703437cA570',
    //   abi: StakingABI,
    //   functionName: 'getUserInformation',
    //   chainId,
    //   args: [address],
    // });
    // convert number;

    // console.log(useReward)
    //
    // if (useReward) {
    //   // setUserClaimedInfo(useReward as UserClaimInfo);
    // }


  }

  const handleClaim = async () => {
    writeContract({
      address: process.env.STAKE_BLITZ_CONTRACT as any,
      abi: StakingABI,
      functionName: 'claimReward',
      chainId
    })
  }

  useEffect(() => {
    const handleLogin = async () => {
      if (address && !localStorage.getItem("accessToken")) {
        setOpenSignMessage(true);
      }else if (localStorage.getItem("accessToken") && isConnected){
        fetchDataOnScreen();
      }
    };
    handleLogin();
    
  }, [isConnected]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        const userProfileData = await getUserProfile();
        if (userProfileData) {
          setOpenSignMessage(false);
        }
        // setUserData(userProfileData);
        await fetchDataOnScreen();
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  return (
    <BasePage>
      <>
        <div className="info-frame">
          <div className="rectangle">
            <div className="header-rectangle">
              <div className="left-header-rectangle">
                <div className="text-info">{t("currentBalance")}</div>
                <div className="value-info">
                  {data ? parseFloat(formatEther(data?.value)).toFixed(5) : "0.00000 ETH"} {data?.symbol}
                </div>
              </div>
              <div className="right-header-rectangle">
                <span
                  className="blur"
                  style={{ backgroundColor: "rgba(123, 255, 200, 0.8)" }}
                />
                <div className="image-header-1">
                  <img src={CurrencyExchange} alt="" />
                </div>
              </div>
            </div>
            <div className="detail-text">
              <div className="detail-1">
                <div className="left-text">{t("expectedRewardRate")}</div>
                <div className="right-text">{(isConnected && percentage) ? 
                  `${parseFloat(((percentage.protocolRewardRate + percentage.tipNewRewardRate)/365).toString()).toFixed(3)}%` : "--"}</div>
              </div>
              <div className="detail-1">
                <div className="left-text">  </div>
                <div className="right-text">  </div>
              </div>
            </div>
          </div>
          <div className="rectangle">
            <div className="header-rectangle">
              <div className="left-header-rectangle">
                <div className="text-info">{t("annualRewardsRate")} </div>
                <div className="value-info">{(isConnected && percentage) ? 
                  `${(percentage.protocolRewardRate + percentage.tipNewRewardRate).toFixed(2)}%` : "--"}</div>
              </div>
              <div className="right-header-rectangle">
                <span
                  className="blur"
                  style={{ backgroundColor: "rgba(253, 183, 48, 0.8)" }}
                />
                <div className="image-header-2">
                  <img src={TimeLine} alt="" />
                </div>
              </div>
            </div>
            <div className="detail-text">
              <div className="detail-1">
                <div className="left-text">{t("annualProtocolRewardsRate")}</div>
                <div className="right-text">{(isConnected && percentage) ? `${percentage.protocolRewardRate.toFixed(2)}%` : "--"}</div>
              </div>
              <div className="detail-1">
                <div className="left-text">{t("annualTipNewRewardsRate")}</div>
                <div className="right-text">{(isConnected && percentage) ? `${percentage.tipNewRewardRate.toFixed(2)}%` : "--"}</div>
              </div>
            </div>
          </div>
          <div className="rectangle">
            <div className="header-rectangle">
              <div className="left-header-rectangle">
                <div className="text-info">{t("unclaimedRewards")}</div>
                <div className="value-info">{isConnected ? (userClaimedInfo?.unclaimedReward.toString() || 0) : "0.00000"}{" "}ETH</div>
              </div>
              <div className="right-header-rectangle">
                <div className="image-header">
                  <span
                    className="blur"
                    style={{ backgroundColor: "#008cd6" }}
                  />
                  <button 
                    disabled={userClaimedInfo?.unclaimedReward.toString() === "0.00000" || !isConnected} 
                    className={`button-claim ${userClaimedInfo?.unclaimedReward.toString() === "0.00000" || !isConnected
                      ? "disable" : ""}`
                    } 
                    onClick={handleClaim}
                  >
                    <div>{t("claim").toUpperCase()}</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="detail-text">
              <div className="detail-1">
                <div className="left-text">{t("claimedTipMevRewards")}</div>
                <div className="right-text">{isConnected ? (userClaimedInfo?.claimedReward.toString() || 0) : "0.00000"}{" "}ETH</div>
              </div>
              <div className="detail-1">
                <div className="left-text">{t("totalTipMevRewards")}</div>
                <div className="right-text">{isConnected ? (userClaimedInfo?.totalReward.toString() || 0) : "0.00000"}{" "}ETH</div>
              </div>
            </div>
          </div>
        </div>
        <div className="list-validators">
          {isConnected && localStorage.getItem("accessToken") ? (<DashboardList/>) : <div className="list-validators"/>}
        </div>
        <ModalSignMessage
          isOpen={openSignMessage}
          isClose={() => setOpenSignMessage(false)}
          onSuccess={async () => {
            await fetchDataOnScreen();
          }}
        />
      </>
    </BasePage>
  );
};

export default Dashboard;
