import axios from "axios";
import apiController from "./index";

export interface UserProfileResponse {
    id: number;
    address: string;
    email: string | null;
    isLogin: boolean;
    isVerify: boolean;
    verificationToken: string | null;

}

export const getUserProfile = async():Promise<UserProfileResponse> => {
    try {
        const result = await apiController.get('/user/profile');
        if (result.status === 200) {
            return result.data as UserProfileResponse;
        } else {
            throw new Error('Failed to fetch user profile');
        }
    } catch (e) {
        throw e;
    }
}

export const updateUserEmail = async(email: string) => {
    try {
        const result = await apiController.post(
            '/user/email/update',
            {email: email})
        if (result.status === 200) {
            return result.data
        }else {
            throw new Error('Failed to update data');
        }
    }catch (e) {
        throw e;
    }
}
export const resendVerifyEmail = async(email: string) => {
    try {
        const result = await apiController.post(
            '/user/verify/send-email',
            {email: email})
        if (result.status === 200) {
            return result.data
        }else {
            throw new Error('Failed to update data');
        }
    }catch (e) {
        throw e;
    }
}

export const getAnnualRewardRate = async () => {
    try {
        const result = await apiController.get('/user/annual-reward-rate');
        if (result.status === 200) {
            return result.data;
        }
        throw new Error('Failed to fetch annual reward rate');
    } catch (e) {
        throw e;
    }
}