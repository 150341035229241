import axios, {AxiosInstance, AxiosResponse, AxiosError, AxiosRequestConfig} from 'axios';
import { logout } from './authen.api';

const API_URL = process.env.API_URL as any;
// const API_URL = "http://localhost:4000";

const axiosInstance: AxiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});
interface RequestOptions extends AxiosRequestConfig {
    params?: any;
    body?: any;
}
axiosInstance.interceptors.request.use(
    (config) => {
        // Check if token exists in localStorage
        const token = localStorage.getItem('accessToken');
        if (token) {
            // Ensure config.headers is defined before adding the token
            config.headers = config.headers || {};
            // Add the token to the Authorization header
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config; // Return the modified config
    },
    (error: any) => {
        // Handle request error
        return Promise.reject(error);
    }
);

let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

axiosInstance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
        const { config, response } = error;
        const originalRequest = config as AxiosRequestConfig & { _retry?: boolean };

        if (response && response.status === 401 && !originalRequest._retry) {
            if (!isRefreshing) {
                isRefreshing = true;
                refreshToken()
                    .then((newToken) => {
                        isRefreshing = false;
                        onRrefreshed(newToken);
                    })
                    .catch((refreshError) => {
                        isRefreshing = false;
                        refreshSubscribers = [];
                        logout();
                        window.location.href = `${window.location.origin}/dashboard`;
                    });
            }

            return new Promise((resolve) => {
                refreshSubscribers.push((token: string) => {
                    if (originalRequest.headers) {
                        originalRequest.headers['Authorization'] = `Bearer ${token}`;
                    }
                    originalRequest._retry = true;
                    resolve(axiosInstance(originalRequest));
                });
            });
        }

        return Promise.reject(error);
    }
);

// Function to refresh token
function refreshToken(): Promise<string> {
    return axiosInstance.post('/refreshToken')
        .then((response: AxiosResponse) => {
            const { accessToken } = response.data;
            return accessToken;
        })
        .catch((error: AxiosError) => {
            console.error('Error refreshing token:', error);
            throw error;
        });
}
function onRrefreshed(token: string) {
    refreshSubscribers.forEach((callback) => callback(token));
    refreshSubscribers = [];
}

const apiController = {

    get: (path: string, options?: RequestOptions): Promise<AxiosResponse> => {
        return axiosInstance.get(path, options);
    },

    post: (path: string, body?: any, options?: RequestOptions): Promise<AxiosResponse> => {
        return axiosInstance.post(path, body, options);
    },

    put: (path: string, body?: any, options?: RequestOptions): Promise<AxiosResponse> => {
        return axiosInstance.put(path, body, options);
    },

    delete: (path: string, options?: RequestOptions): Promise<AxiosResponse> => {
        return axiosInstance.delete(path, options);
    },
};

export default apiController;
