import React from "react";
import { Chip } from "@mui/material";

// Enums definition
export enum StatusEnum {
  DRAFT = 0,
  SUBMITTED = 1,
  PENDING = 2,
  ACTIVE = 3,
  DEACTIVE = 4,
  "PENDING WITHDRAWAL" = 5,
}

export enum ColorStatus {
  DRAFT = "primary",
  SUBMITTED = "info",
  PENDING = "secondary",
  ACTIVE = "success",
  DEACTIVE = "error",
  "PENDING WITHDRAWAL" = "warning",
}

// Component
const StatusChip: React.FC<{ text: string }> = ({ text }) => {
  const statusKey = Number(text) as unknown as keyof typeof StatusEnum;
  const statusLabel = StatusEnum[statusKey];
  const color = ColorStatus[statusLabel as unknown as keyof typeof ColorStatus] as
    | "info"
    | "warning"
    | "success"
    | "error";

  return <Chip label={statusLabel} color={color} variant="outlined" />;
};

export default StatusChip;
